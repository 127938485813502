import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import './5SMain.scss';
import BasicButton from 'components/Button/BasicButton';
import InputWithLabel from 'components/Input/InputWithLabel/InputWithLabel';
// @ts-ignore
import ReactTable from 'react-table-legacy';
import moment from 'moment';
import 'moment/locale/es';
import LoadingSpinner from 'components/LoadingSpinner';
import ImageLinks from 'utils/ImageLinks';
import { Button, Modal as MaterialModal } from '@mui/material';
import { useDelete5SChecklistsMutation, useGetAll5SByLocationIdLazyQuery, useUpdate5SChecklistsMutation } from 'generated/graphql';
import Modal from 'components/Modal';
import { toast } from 'react-toastify';
import req from 'utils/request';
import { capitalizeFirst } from 'utils/stringHelpers';
import { useDebounce } from 'features/internal-users/user/UsersIndex/UsersIndex';
import { selectLocationsToAccess, selectSelectedLocation } from 'store/selectors/globalFilters.selectors';
import { getAreasForLocationById } from 'store/actions/distributionCenter.actions';
// import { selectUser } from 'store/selectors/user.selectors';
// import { TRANSLATIONS } from 'types/enums';
// import { useTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import userSearch from '../../../../assets/icons/input-search.svg';

const WebPortal5SMain = () => {
  const { t } = useTranslation();
  const lng = ['en-US', 'en'].includes(i18next.language) ? 'en' : 'es';
  const [locationId] = useSelector(selectLocationsToAccess);

  const enNamesOfMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const esNamesOfMonths = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  const monthNames = lng === 'en'
    ? enNamesOfMonths
    : esNamesOfMonths;

  const currentMonth = new Date().getMonth();
  const futureMonths = monthNames.slice(currentMonth);

  const [areaId, setAreaId] = useState<number>();
  const [subAreaId, setSubAreaId] = useState<number>();
  const [monthNumber, setMonthNumber] = useState<number>(currentMonth + 1);
  const [status, setStatus] = useState<string>();
  const [areas, setAreas] = useState([]);
  const [subAreas, setSubAreas] = useState([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState('');
  const [qrSearchText, setQrSearchText] = useState('');
  const [qrData, setQrData] = useState<any>();
  const [filteredQrData, setFilteredQrData] = useState<any>();
  const [searchQrLoading, setSearchQrLoading] = useState(false);
  const isDCSelected = useSelector(selectSelectedLocation);

  // const loggedInUser = useSelector(selectUser);
  // const { email: loggedInUserEmail } = loggedInUser.toJS();

  // const [searchedRoutines, setSearchedRoutines] = useState<any>([]);
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  /*
  const resetFilters = {
    userType: '',
    locationSlug: '',
    departmentSlug: '',
    areaSlug: '',
    subAreaSlug: '',
    roleType: '',
    roleSlug: '',
    limit: 20,
    page: 1
  };
  const [filters, setFilters] = useState(resetFilters);
   */
  const [data5S, setData5S] = useState<any>([]);
  const [isMonthShown, setIsMonthShown] = useState<boolean>(false);
  const [monthSelected, setMonthSelected] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [uploading, setLoading] = useState(false);
  const [fileDownloadLink, setFileDownloadLink] = useState<string>('');
  const fileRef = useRef<HTMLInputElement>(null);
  const [showTickIcon, setShowTickIcon] = useState(false);
  const [selectedChecklistId, setSelectedChecklistId] = useState();
  const [showQRsModal, setShowQRsModal] = useState(false);
  const [selectedQr, setSelectedQr] = useState<string>('');
  const [selectedAssignedChecklistId, setSelectedAssignedChecklistId] = useState<string>('');
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [fetchGetAll5s, {
    loading: isLoading5SData,
    data,
    refetch: refetchGetAll5s,
  }] = useGetAll5SByLocationIdLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const getAreasAndSubareas = async () => {
    const res = await getAreasForLocationById(locationId);
    if (res.areas && res.areas.length > 0) {
      const insideDcAreas = res.areas.filter((area: any) => area.locationType === 'INSIDE_DC');
      setAreas(insideDcAreas);
    }
  };

  const handleOpenInstructions = () => setIsOpen(true);

  useEffect(() => {
    getAreasAndSubareas();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAreaChange = (area: any) => {
    const newArea = JSON.parse(area);
    setAreaId(newArea.id);
    setSubAreas(newArea.subAreas);
    setSubAreaId(undefined);
  };

  const [deleteChecklist] = useDelete5SChecklistsMutation();
  const [updateChecklist] = useUpdate5SChecklistsMutation({
    onError: err => {
      console.log(err.message);
    },
  });
  const handleSearch = async (event: any) => {
    setSearch(event.target.value);
    fetchGetAll5s({
      variables: {
        userSearch: event.target.value ? event.target.value : '',
        locationId,
        monthNumber,
        areaId,
        subAreaId,
        status,
      },
    });
  };

  const toCamelCase = (str: string) => (str
    ? str
      .toLowerCase()
      .replace(/(^\w|\s\w)(\S*)/g, (_ignore, m1, m2) => m1.toUpperCase() + m2.toLowerCase())
    : '');

  useEffect(() => {
    const formatted = (data?.getAll5SByLocationId || []).map((eachData: any) => {
      return {
        mes: eachData?.monthNumber,
        area: eachData?.workLocation?.name,
        subArea: eachData?.subLocation?.name,
        usuario: eachData?.assignedToUser?.name,
        qr: eachData?.assignedToUser?.shortSlug,
        sharp: eachData?.assignedToUser?.employeeNumber,
        estado: eachData?.checklistInfo?.status,
        checklistData: { assignedToChecklistId: eachData?.assignedToUser?.id,
          checklistsId: eachData?.checklistInfo?.checklist?.id,
          userChecklistId: eachData?.id },
      };
    });
    setData5S(formatted);
    const qrsData: any = {};
    formatted.forEach((d: any) => {
      if (!qrsData[d.qr] && d.qr !== undefined) {
        qrsData[d.qr] = d.checklistData.assignedToChecklistId;
      }
      setQrData(qrsData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);

  const onHandleDeleteChecklist = () => {
    setShowDownloadModal(true);
  };

  useEffect(() => {
    if (qrData) setFilteredQrData(qrData);
  }, [qrData]);

  const onHandleDeleteUnAnsweredChecklist = () => {
    const monthNamesENES = navigator.language.includes('en')
      ? enNamesOfMonths
      : esNamesOfMonths;

    const monthIndex = monthNamesENES.indexOf(monthSelected);
    const newDate = new Date();

    const date = new Date(newDate.getFullYear(), monthIndex, 1);
    setDeleteIsLoading(true);
    deleteChecklist({
      variables: {
        args: {
          date,
          locationId,
        },
      },
    })
      .then(() => {
        setTimeout(() => {
          fetchGetAll5s({
            variables: {
              locationId,
              monthNumber,
              areaId,
              subAreaId,
              status,
            },
          });
          setDeleteIsLoading(false);
        }, 8000);
        setShowDownloadModal(false);
        setShowConfirmationModal(true);
      });
  };

  const onHandleDeleteEachChecklist = (eachChecklist: any) => {
    const monthNamesENES = navigator.language.includes('en')
      ? enNamesOfMonths
      : esNamesOfMonths;

    const monthIndex = monthNamesENES.indexOf(monthSelected);
    const newDate = new Date();

    const date = new Date(newDate.getFullYear(), monthIndex, 1);

    deleteChecklist({
      variables: {
        args: {
          date,
          checklistsId: [eachChecklist?.checklistData?.checklistsId],
        },
      },
    })
      .then(() => {
        fetchGetAll5s({
          variables: {
            locationId,
            monthNumber,
            areaId,
            subAreaId,
            status,
          },
        });
      });
  };

  const onHandleEditChecklist = (eachChecklist: any) => {
    const { estado } = eachChecklist;
    if (estado === 'PENDING') {
      if (showTickIcon) {
        if (selectedAssignedChecklistId) {
          updateChecklist({
            variables: {
              args: {
                userChecklistId: eachChecklist?.checklistData?.userChecklistId,
                assignedUserId: selectedAssignedChecklistId,
              },
            },
          }).then(() => {
            setShowTickIcon(false);
            setSelectedAssignedChecklistId('');
            setSelectedChecklistId(undefined);
            fetchGetAll5s({
              variables: {
                locationId,
                monthNumber,
                areaId,
                subAreaId,
                status,
              },
            });
          });
        } else {
          setShowTickIcon(false);
          setSelectedAssignedChecklistId('');
          setSelectedChecklistId(undefined);
        }
      } else {
        setSelectedChecklistId(eachChecklist?.checklistData?.checklistsId);
        setShowTickIcon(true);
      }
    }
  };

  const handleQRClick = (qr: any) => {
    setShowQRsModal(true);
    setSelectedQr(qr);
    setSearchQrLoading(false);
    setFilteredQrData(qrData);
  };

  const getUserInfo = async () => {
    try {
      const res = await req.get(
        `/api/v1/users-list?shortSlug=${qrSearchText}&locationSlug=${isDCSelected.locationSlug}`,
      );
      const qrsData: any = {};
      res.users.forEach((d: any) => {
        if (!qrsData[d.shortSlug] && d.shortSlug !== undefined) {
          qrsData[d.shortSlug] = d.id;
        }
      });
      setFilteredQrData(qrsData);
      setSearchQrLoading(false);
    } catch (e) {
      console.log('the errror: ', e);
    }
  };

  const debouncedUsersReq = useDebounce(qrSearchText, 500);

  useEffect(() => {
    if (qrSearchText) getUserInfo();
    // eslint-disable-next-line
  }, [debouncedUsersReq]);

  const onHandleQrChange = async (e: any) => {
    if (e.target.value) {
      const filteredData = Object.fromEntries(
        Object.entries(qrData).filter(([key]) => key.includes(e.target.value)),
      );
      if (Object.keys(filteredData).length > 0) {
        setSearchQrLoading(false);
        setFilteredQrData(filteredData);
      } else {
        setSearchQrLoading(true);
        setQrSearchText(e.target.value);
      }
    } else {
      setSearchQrLoading(false);
      setFilteredQrData(qrData);
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Mes',
        accessor: 'mes',
        Cell: (v: any) => {
          return v.value ? <div style={{ textAlign: 'left' }}>{moment().month(v.value - 1).format('MMMM')}</div> : 'N/A';
        },
      },
      {
        Header: 'Area',
        accessor: 'area',
        Cell: (v: any) => {
          return v.value ? <div style={{ textAlign: 'left' }}>{toCamelCase(String(v.value))}</div> : 'N/A';
        },
      },
      {
        Header: 'Subarea',
        accessor: 'subArea',
        Cell: (v: any) => {
          return v.value ? <div style={{ textAlign: 'left' }}>{toCamelCase(String(v.value))}</div> : 'N/A';
        },
      },
      {
        Header: 'usuario',
        accessor: 'usuario',
        Cell: (v: any) => {
          return <div style={{ textAlign: 'left', width: 'auto' }}>{String(v.value ?? 'N/A')}</div>;
        },
      },
      {
        Header: 'SHARP',
        accessor: 'sharp',
        Cell: (v: any) => {
          return <div style={{ display: 'flex' }}>{String((v?.original?.roleId ? v?.original?.userRole?.name : v.value) ?? 'N/A')}</div>;
        },
      },
      {
        Header: 'QR',
        accessor: 'qr',
        Cell: (v: any) => {
          const checklistId = v?.row?.checklistData?.checklistsId;
          return (
            <div
              role="button"
              style={{ display: 'flex' }}
              onClick={() => {
                if (showTickIcon && checklistId === selectedChecklistId) handleQRClick(v?.row?.qr);
              }}
            >
              <b>{showTickIcon && checklistId === selectedChecklistId && selectedQr ? selectedQr : String(v.value ?? 'N/A')}</b>
              {(showTickIcon && checklistId === selectedChecklistId) && <img src={ImageLinks.chevronDownBlack} width={20} height={20} style={{ cursor: 'pointer' }} alt="" />}
            </div>
          );
        },
      },
      {
        Header: 'Estado',
        accessor: 'estado',
        Cell: ({ row }: any) => {
          return <div style={{ display: 'flex' }}>{capitalizeFirst(row?.estado)}</div>;
        },
      },
      {
        accessor: 'checklistData',
        Cell: ({ row }: any) => {
          const isChecklistApplied = row?.estado;
          if (isChecklistApplied === 'APPLIED') {
            return null;
          }
          const checklistId = row?.checklistData?.checklistsId;
          return (
            <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
              <img src={ImageLinks.delete} alt="delete" width={50} onClick={() => onHandleDeleteEachChecklist(row)} style={{ cursor: 'pointer' }} />
              <img src={(showTickIcon && checklistId === selectedChecklistId) ? ImageLinks.blueBgTick : ImageLinks.edit} alt="edit" width={20} height={20} onClick={() => onHandleEditChecklist(row)} style={{ cursor: 'pointer' }} />
            </div>
          );
        },
      },
    ] as any;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, showTickIcon, selectedQr, selectedAssignedChecklistId]);

  const handleUploadButton = () => {
    if (fileRef.current !== null) {
      fileRef.current.click();
    }
  };

  useEffect(() => {
    fetchGetAll5s({
      variables: {
        locationId,
        monthNumber,
        areaId,
        subAreaId,
        status,
      },
    });
  }, [fetchGetAll5s, locationId, areaId, subAreaId, monthNumber, status]);

  const onHandleMonth = () => {
    setIsMonthShown(!isMonthShown);
  };

  const cleanFilters = () => {
    setMonthNumber(currentMonth + 1);
    setStatus(undefined);
    setAreaId(undefined);
    setSubAreaId(undefined);
  };

  const handleAttachmentsUpload = async (e: any) => {
    setLoading(true);
    const { files } = e.target;
    if (files?.length) {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      const date = new Date();
      const year = date.getUTCFullYear().toString();
      const currentMonthIndex = date.getMonth();
      const formData = new FormData();

      formData.set('file', files[0]);
      formData.set('year', year);
      formData.set('currentMonthIndex', currentMonthIndex.toString());

      try {
        await req.post('/api/v1/upload-checklist-users', formData, config);
        setLoading(false);
        toast.success('Uploaded Successfully!');
        if (fileRef.current !== null) {
          fileRef.current.value = '';
        }
      } catch (err) {
        toast.error(`Error while uploading: ${err} `);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const response = await req.get('/api/v1/5s/5S-annual-template-link');
        setFileDownloadLink(response.annualPlanUploadURL);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const selectedMonthName = t(monthSelected.toUpperCase());

  const onClickCancel = () => {
    refetchGetAll5s?.(
      {
        userSearch: search,
        locationId,
        monthNumber,
        areaId,
        subAreaId,
        status,
      },
    );
  };

  const handleCloseInstructions = () => {
    setIsOpen(false);
    onClickCancel();
  };

  return (
    <div className="page-container">
      {
          !isOpen && (
          <>
            <div className="wo__container">
              <div className="wo__flex-row">
                <div className="main-title">5S Calendarización</div>
                <div>
                  <BasicButton text="Ingresar calendarización" style={{ backgroundColor: '#EFBE3F', height: 'auto', borderRadius: 4, fontWeight: 500 }} className="checklist_section_update_template_button_filled" onClick={handleOpenInstructions} />
                  <Button style={{ backgroundColor: '#E92B36', marginLeft: 16, height: 'auto', borderRadius: 4, fontWeight: 500 }} className="checklist_section_update_template_button_filled" onClick={onHandleDeleteChecklist}>
                    <img src={ImageLinks.whiteDelete} alt="icon" width={22} />
                    <span style={{ color: 'white', textTransform: 'capitalize', padding: '6px 10px' }}>Eliminar Calendarización</span>
                  </Button>
                  {/* <BasicButton
                  text="Tutorial"
                  className="tutorial-class"
                  onClick={() => {}} /> */}
                </div>
              </div>
              <>
                <div className="description-base">
                  Busca y revisa los checklist de 5s. Además, puedes eliminar las
                  calendarizaciones pendientes.
                </div>
                <InputWithLabel
                  inputRef={inputRef}
                  inputImg={userSearch}
                  onChange={handleSearch}
                  placeholder="Busca por Usuario, SHARP, QR"
                  value={search}
                  labelClassName="search-header"
                  type="text"
                  inputClassName="search-input"
                />
                <div className="wo__flex-row" style={{ justifyContent: 'flex-start', gap: 8 }}>
                  <div className="filter__flex-row">
                    <select
                      name="Mes"
                      onChange={e => setMonthNumber(Number(e.target.value))}
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        fontWeight: 'bold',
                        width: '100px',
                        outline: 'none',
                      }}
                    >
                      <option
                        selected={monthNumber === currentMonth + 1}
                        value={currentMonth + 1}
                      >
                        {monthNames[currentMonth]}
                      </option>
                      {monthNames
                        .slice(currentMonth + 1, monthNames.length)
                        .map((monthName: string, index: number) => (
                          <option
                            key={index.toString()}
                            value={index + currentMonth + 2}
                          >
                            {monthName}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="filter__flex-row">
                    <select
                      name="Area"
                      onChange={e => handleAreaChange(e.target.value)}
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        fontWeight: 'bold',
                        width: '100px',
                        outline: 'none',
                      }}
                    >
                      <option selected={areaId === undefined} value="">Area</option>
                      {areas.map((area: any) => (
                        <option key={area.id} value={JSON.stringify(area)}>{lng === 'en' ? area.nameEN ? area.nameEN : area.name : area.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="filter__flex-row">

                    <select
                      name="Subarea"
                      onChange={e => setSubAreaId(Number(e.target.value))}
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        fontWeight: 'bold',
                        width: '100px',
                        outline: 'none',
                      }}
                    >
                      <option selected={subAreaId === undefined} value="none">Subareas</option>
                      {subAreas.map((subArea: any) => (
                        <option key={subArea.id} value={subArea.id}>{lng === 'en' ? subArea.nameEN ? subArea.nameEN : subArea.name : subArea.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="filter__flex-row">
                    <select
                      name="Estado"
                      onChange={e => setStatus(e.target.value)}
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        fontWeight: 'bold',
                        width: '100px',
                        outline: 'none',
                      }}
                    >
                      <option selected={status === undefined} value="none">Estado</option>
                      <option value="PENDING">Pending</option>
                      <option value="APPLIED">Applied</option>
                    </select>
                  </div>
                  <div role="button" onClick={cleanFilters} className="filter__flex-row" style={{ backgroundColor: '#FFEBEB', color: '#E92B36', width: 'auto' }}>
                    Limpiar filtros
                  </div>
                </div>
              </>

              {(!(isLoading5SData || deleteIsLoading)) ? (
                <div style={{ marginTop: 20 }}>
                  <ReactTable
                    minRows={0}
                    data={data5S}
                    columns={columns}
                    showPagination
                    className="-striped max-height center"
                  />
                </div>
              ) : (
                <div style={{ height: 400 }}>
                  <LoadingSpinner positionTop={200} />
                </div>
              )}
            </div>
          </>
          )
      }
      <div className="instruction-wrapper">
        <div className="checklist_section__body">
          {isOpen && (
            <>
              <div className="checklist_section_instructions">
                <h2>Instrucciones</h2>
                <BasicButton text="Cancelar" className="checklist_section_button_filled" onClick={handleCloseInstructions} />
              </div>
              <div>
                El plan anual se subirá solamente una vez al año,
                para ello se recomienda seguir las instrucciones siguientes:
              </div>
              <ol>
                <li>
                  Si aún no se tiene la plantilla a subir correcta,
                  será necesario descargarla pulsando “Descargar plantilla”

                  <ul>
                    <li>
                      Seleccionar la BU
                    </li>
                    <li>
                      Seleccionar el centro de distribución
                    </li>
                    <li>
                      Seleccionar el área deseada
                    </li>
                    <li>
                      Seleccionar las subárea donde se aplicaran 5S
                    </li>
                    <li>
                      Seleccionar el checklist a aplicarse en esta locación específica
                    </li>
                    <li>
                      En el cruce con de las filas con las columnas de los meses se estará
                      ingresando el ID del empleado que estará aplicando dicho checklist
                    </li>
                  </ul>

                </li>
                <li>
                  Una vez se tenga la plantilla con los datos esperados,
                  se deberá asegurar que no se muestren ningún tipo de errores en el archivo,
                  de mostrarse alguno de ellos se
                  deberá cambiar de opción seleccionada por una válida.
                </li>
                <li>
                  Ahora que se tiene el archivo de manera correcta se puede proceder a subirla
                </li>
                <li>
                  Para ello se deberá dar clic en la opción “Subir plantilla”
                </li>
                <li>
                  El siguiente paso será seleccionar el archivo del plan maestro,
                  y subirlo a la plataforma en el apartado correspondiente,
                  de ser exitoso se podrá observar las asignaciones,
                  de no ser así se deberá volver al paso 1
                </li>
              </ol>

              <div>
                {uploading && <LoadingSpinner />}
                <a target="_blank" rel="noreferrer" href={fileDownloadLink} title={fileDownloadLink} data-renderer-mark="true">
                  <BasicButton text="Descargar plantilla" className="checklist_section_button" />
                </a>

                <BasicButton
                  text={uploading ? 'Cargando artículo' : 'Subir plantilla'}
                  className="checklist_section_button_filled"
                  onClick={handleUploadButton}
                />
                <input
                  ref={fileRef}
                  className="hidden"
                  type="file"
                  onChange={handleAttachmentsUpload}
                  name="file"
                  multiple
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
              </div>
            </>
          )}
        </div>
      </div>

      {showDownloadModal && (
        <Modal
          modalContainerStyles={{ width: '50vw',
            left: '30%',
            maxHeight: '90vh',
            minHeight: '20vh',
            height: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)' }}
        >
          <div className="downloadMoal">
            <p className="downloadMoal__title" style={{ textAlign: 'center', marginTop: 20 }}>¿Deseas eliminar las calendarizaciones?</p>
            <p style={{ textAlign: 'center', marginTop: 20 }}>Estamos a punto de eliminar las calendarizaciones (los checklists) pendientes del mes {selectedMonthName}, los que aún no han sido respondidos.</p>
            <p style={{ textAlign: 'center' }}>¿Estás seguro de que deseas eliminarlas?</p>
            <div>
              <p>Mes</p>
              <p onClick={onHandleMonth}> <b>{monthSelected || 'Seleccionar' }</b> <img src={ImageLinks.chevronDownIcon} alt="failed request" width={15} height={15} /></p>
              {isMonthShown && (
              <div style={{ height: 60, overflowY: 'scroll', cursor: 'pointer', width: 120 }}>
                {futureMonths.map(eachMonth => (<div role="button" onClick={() => { setMonthSelected(eachMonth); setIsMonthShown(false); }}>{eachMonth}</div>))}
              </div>
              )}
            </div>
            <div className="downloadMoal__button_container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
              <BasicButton text="Cancelar" className="cancel-button" onClick={() => setShowDownloadModal(false)} />
              <BasicButton disabled={!monthSelected} onClick={onHandleDeleteUnAnsweredChecklist} text="Si, eliminar" className="eliminar-button" />
            </div>
          </div>
        </Modal>
      )}

      {showConfirmationModal && (
      <Modal
        modalContainerStyles={{ width: '50vw',
          left: '30%',
          maxHeight: '90vh',
          minHeight: '20vh',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)' }}
      >
        <div className="downloadMoal" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={ImageLinks.greenFilledCheck} width={24} height={24} style={{ marginTop: 10 }} alt="" />
          <p className="downloadMoal__title" style={{ textAlign: 'center', marginTop: 20 }}>Las calendarizaciones eliminadas con éxito.</p>
          <p style={{ textAlign: 'center', marginTop: 20 }}>El centro de distribución ahora está vacío de calendarizaciones para el mes de {selectedMonthName}, por lo que es importante ingresar nuevas si es necesario.</p>
          <p style={{ textAlign: 'center' }}>Te recomendamos revisar los checklists que se completaron en el mes eliminado para determinar si es necesario incluirlos en la nueva calendarización.</p>
          <div className="downloadMoal__button_container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
            <BasicButton text="Volver" className="tutorial-class" onClick={() => setShowConfirmationModal(false)} />
          </div>
        </div>
      </Modal>
      )}

      <MaterialModal
        open={showQRsModal}
        onClose={() => setShowQRsModal(false)}
        BackdropProps={{ invisible: true }}
      >
        <div className="searchDropdown">
          <div className="searchBar">
            <img
              src={ImageLinks.search_black}
              height={24}
              width={24}
              style={{
                objectFit: 'contain',
              }}
              alt=""
            />
            <input
              type="text"
              style={{
                flex: 1,
              }}
              placeholder="Buscar usuario por QR"
              onChange={onHandleQrChange}
            />
          </div>

          <div style={{ marginTop: 40, overflowY: 'auto' }}>
            <p className="subLine">Usuarios en el CD seleccionado</p>

            {searchQrLoading ? (
              <div style={{
                height: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              >
                <LoadingSpinner height={20} text="Searching..." />
              </div>
            ) : (
              <div style={{ marginTop: 12 }}>
                {filteredQrData && Object.keys(filteredQrData).map((key: string) => (
                  <div
                    role="button"
                    className="search_content"
                    onClick={() => {
                      setSelectedAssignedChecklistId(filteredQrData[key]);
                      setShowQRsModal(false);
                      setSelectedQr(key);
                    }}
                    key={key}
                  >
                    <span style={{ fontSize: 16, fontWeight: 'normal' }}>{key}</span>
                    {key === selectedQr && <img src={ImageLinks.greenFilledCheck} width={24} height={24} style={{ objectFit: 'contain' }} alt="" /> }
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </MaterialModal>
    </div>
  );
};

export default WebPortal5SMain;
